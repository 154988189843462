.card{
  margin: 5% 0%;
  width: 353px;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
}

.card-body{
  margin: 0% 0% 0% 3%;
  padding: 6% 0%;
}


/* pricing card start */

.btncolor{
  background-color: #3482dd;
  border-radius: 55555px;
  padding: 1rem 2rem!important;
  color: #ffff;
  margin-bottom: 2rem;
  
}


.package{
  list-style-type: none;
}

.pricing{
 padding-left: 1.5rem;
}

.parent{
  height: 300px;
  display: flex;
  border:2px solid yellow;
}

.child{
  background-color: red;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  color: white;
}

.icon-color
{
  color:#1f38fa;

}



/* pricing card end  */

/* footer start */
/* Footer.css */
.footer {
  padding: 4rem 0;
}

.footer .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer .footer-links ul li {
  margin-bottom: 0.5rem; /* Space between links */
}

.footer .footer-links ul li a {
  color: white;
  text-decoration: none; /* Remove underline */
}

.footer .footer-links ul li a:hover {
  text-decoration: underline; /* Optional: underline on hover */
}

.footer .credits a {
  color: white;
  text-decoration: none; /* Remove underline */
}

.footer .credits a:hover {
  text-decoration: underline; /* Optional: underline on hover */
}

/* footer end */


/* technology start*/
.header {
  width: 100%;
  height: 100vh;
  background: 
    url("./assets/technology.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: auto;
  position: relative;
}

.aboutus {
  width: 100%;
  height: 100vh;
  background: 
    url("./assets/about.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: auto;
  position: relative;
}

.about-text {
  font-size: 120%;
  color: #fff;
  letter-spacing: 1px;
  position: absolute;
  top: 50%;
  left: 38%;
  transform: translate(-50%, -50%);
}

.header-text {
  font-size: 120%;
  color: #fff;
  letter-spacing: 1px;
  position: absolute;
  top: 50%;
  left: 49%;
  transform: translate(-50%, -50%);
}


.contact {
  width: 100%;
  height: 70vh;
  background: 
    url("./assets/contact.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: auto;
  position: relative;
}
/* technology end */


/*  */
.contact-section {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  background-color: #f9f9f9;
}

.contact-details,
.contact-form {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 40px;
}

.contact-details {
  flex: 1;
  background-color: #f9f9f9;
}

.contact-form {
  flex: 1;
  background-color: #ffffff;
}

.form-group {
  margin-bottom: 15px;
}

.form-control {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.col-lg-6 {
  flex: 1;
}


/* login start*/
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);
  z-index: 1000;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.login-popup,
.signup-popup {
  display: flex;
  flex-direction: column;
}

.forgot-password {
  margin-top: 10px;
  text-align: right;
}

.forgot-password a {
  color: #007bff;
  text-decoration: none;
}

.forgot-password a:hover {
  text-decoration: underline;
}
.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.popup-header h2 {
  margin: 0;
}

.popup-header .fa-times {
  color: #888;
}

.popup-header .fa-times:hover {
  color: #000;
}



/* login end */